.blockLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
}

.loader {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.5);
    border-top-color: transparent;
    animation: rot1 1.2s linear infinite;
}

@keyframes rot1 {
    to {
        transform: rotate(360deg);
    }
}