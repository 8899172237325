.main {
    background-color: #F5F5F5;
    width: 100%;
    position: relative;
    border-radius: 25px;
}

.headerText {
    width: 500px;
    font-size: 1.7rem;
    font-weight: 700;
}

.stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    gap: 50px;
    flex-wrap: wrap;
    position: relative;
}
.statItem {
    width: 250px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.statItemHeader {
    font-size: 1.5rem;
    font-weight: 700;
}
.statItemBody {
    font-size: 1rem;
}

@media screen and (max-width: 1024px) {
    .headerText {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }
    .statItem {
        align-items: center;
        text-align: center;
    }
    .stats {
        justify-content: center;
    }
}

@media screen and (max-width: 425px) {
    .headerText {
        font-size: 1.2rem;
    }
    .statItemHeader {
        font-size: 1.2rem;
    }
}