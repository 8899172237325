.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    position: relative;
}
.tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    padding: 8px 0.75rem;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.tabs * {
    z-index: 2;
}

.container input[type="radio"] {
    display: none;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 50px;
    font-size: .8rem;
    color: black;
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: .8rem;
    height: .8rem;
    position: absolute;
    top: 10px;
    left: 30%;
    font-size: 10px;
    margin-left: 0.75rem;
    border-radius: 50%;
    margin: 0px;
    background-color: #e6eef9;
    transition: 0.15s ease-in;
}

.container input[type="radio"]:checked + label {
    color: #185ee0;
}

.container input[type="radio"]:checked + label > .notification {
    background-color: #185ee0;
    color: #fff;
    margin: 0px;
}

.container input[id="radio-1"]:checked ~ .glider {
    transform: translateX(-12px);
}

.container input[id="radio-2"]:checked ~ .glider {
    transform: translateX(90px);
}

.container input[id="radio-3"]:checked ~ .glider {
    transform: translateX(192px);
}

.glider {
    position: absolute;
    display: flex;
    height: 30px;
    width: 75px;
    background-color: #e6eef9;
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;
    transform: translateX(-100px);
}


