.main {
    /*background-color: #292929;*/
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.menu {
    height: 100%;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 50px;
    overflow-y: auto;
    overflow-x: hidden;
}

.menu::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.menu::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}
.menu::-webkit-scrollbar-thumb {
    background-color: rgba(17, 23, 26, 0.8);
    border-radius: 10px;
}

.item {
    padding: 0 25px;
    width: 100%;
    height: 50px;
    transition: 0.3s;
    cursor: pointer;
    color: #b4b4b4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    font-size: 1rem;
}

.disabledItem {
    background-color: #333333 !important;
    cursor: default !important;
}

.item:hover {
    /*background-color: #333333;*/
    color: #005ae9;
}

.item[located=true] {
    /*background-color: #333333;*/
    color: #005ae9;
}

.itemText {

}

.itemIcon {
    width: 18px;
    height: 18px;
}

.group {
    padding: 0 25px;
    width: 100%;
    height: 50px;
    transition: 0.3s;
    cursor: pointer;
    color: #b4b4b4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    font-size: 1rem;
    position: relative;
}
.group:hover {
    background-color: #333333;
}

.groupItemText {
    display: block;
    width: 80%;
}

.downIconBlock {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.upIcon {
    transform: rotate(-180deg);
}

.groupItemsListBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-left: 25px;
    /*position: relative;*/
    overflow: hidden;
    transition: 0.3s;
}

.groupItemsListBlock_closed {
    height: 0;
}

.groupItemsListInner {
    width: 100%;
    /*position: absolute;*/
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

