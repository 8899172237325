.card {
    background: #f3f3f3;
    padding: 15px 30px;
    border-radius: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 270px;
}

.card img {
    margin-bottom: 20px;
    height: 60px;
}