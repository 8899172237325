.form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 15px;
    flex-direction: column;
}

.formInputBlock {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 200px;
}

.formInputBlock2 {
    width: 100%;
    margin: 0;
    padding: 0;
}

.formInput {
    font-size: 1rem;
    padding: 10px 15px;
    font-weight: 400 !important;
}