.studyPlanTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow: hidden;
}

.studyPlanTable th, .studyPlanTable td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.studyPlanTable th {
    background-color: #007BFF;
    color: white;
}

.studyPlanTable .section-header {
    background-color: #0056b3;
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
}

.studyPlanTable .section-content {
    display: none;
}

.studyPlanTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.container {
    max-width: 900px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
    .studyPlanTable {
        display: block;
        overflow-x: auto;
        white-space: normal;
    }
    .studyPlanTable * {
       font-size: 0.8rem;
    }
}

