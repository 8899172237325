.blockTemplate {
    box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    padding: 0;
    height: 21cm !important;
    max-height: 21cm !important;
    min-height: 21cm !important;
    width: 29.7cm !important;
    max-width: 29.7cm !important;
    min-width: 29.7cm !important;
    flex-direction: row;
    overflow: hidden;
    overflow-x: auto;
    gap: 0;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 20px;
}

.gradient {
    height: 21cm;
    width: 350px;
    background: rgb(22,181,228);
    background: linear-gradient(180deg, rgba(22,181,228,1) 0%, rgba(18,125,183,1) 50%);
}

.gradient2 {
    height: 500px;
    width: 29.7cm;
    background: rgb(22,181,228);
    background: linear-gradient(270deg, rgba(22,181,228,1) 0%, rgba(18,125,183,1) 50%);
}

.uslovniki2032_aituLogoWhite {
    position: absolute;
    width: 150px;
    height: 75px;
    top: 45px;
    left: 50px;
}
.uslovniki2032_aituLogoWhite img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.uslovniki2032_main {
    width: 29.7cm;
    height: 21cm;
    padding: 100px 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.uslovniki2032_name {
    position: relative;
    text-align: center;
}
.uslovniki2032_name:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: black;
}
.uslovniki2032_astana {
    position: absolute;
    bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    left: 50px;
    width: 150px;
}

.uslovniki2032_astana p {
    color: white;
    font-weight: 600;
    font-size: 1rem;
}

.lager2023_logo {
    position: absolute;
    width: 175px;
    height: 200px;
    top: 45px;
    right: 100px;
}
.lager2023_logo img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.lager2023_aitu_half_logo {
    position: absolute;
    width: 250px;
    height: 275px;
    bottom: 0;
    right: 0;
}
.lager2023_aitu_half_logo img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.lager2023_certificateTxt {
    position: absolute;
    text-align: center;
    font-size: 5rem;
    font-weight: 700;
    color: white;
    left: 100px;
    top: 160px;
}
.lager2023_main {
    width: 29.7cm;
    height: 21cm;
    padding: 10px 100px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    flex-direction: column;
}
.microKva2023_backBlock {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.microKva2023_backBlock img {
    width: 100%;
    height: 100%;
}

.microKva2023_main {
    width: 29.7cm;
    height: 21cm;
    padding: 10px 100px;
    padding-top: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    flex-direction: column;
    z-index: 100;
}
