.box, .boxInfo {
    padding: 10px 40px;
    color: white;
    border-radius: 35px;
    background-color: #163269;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}
.box {
    min-width: 340px;
}

.boxInfo {
    width: fit-content;
    box-sizing: border-box;
    padding: 20px 15px;
}

.boxInfo p {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;

}

.boxInfo ul {
    line-height: 2rem;
    margin: 0;
    font-size: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sectionTitleText {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.structureText {
    font-weight: 400;
    font-size: 1.5rem;
}

/*@media screen and (min-width: 1025px) {*/
/*    .box {*/
/*        flex: 1 1 calc(25% - 16px);*/
/*        max-width: calc(25% - 16px);*/
/*    }*/
/*}*/

@media screen and (max-width: 1024px) {
    /*.box {*/
    /*    flex: 1 1 calc(50% - 16px);*/
    /*    max-width: calc(50% - 16px);*/
    /*}*/

    .sectionTitleText {
        font-weight: 700;
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 768px) {
    .sectionTitleText {
        font-size: 2rem;
    }

    .structureText {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 481px) {
    .box {
        width: 320px;
    }

    .sectionTitleText {
        font-size: 1.5rem;
    }
}

.teacherCard {
    width: 300px;
    text-align: center;
    height: 650px;
}

.documentCard p {
    text-align: center;
}

.box h3 {
    font-weight: 400;
}

.box img {
    height: 30px;
    margin-right: 10px;
}

.box h4 {
    display: flex;
    align-items: center;
}

.box h3 {
    margin-top: 0;
    margin-bottom: 21px;
}

.box h4 {
    margin-top: 21px;
    margin-bottom: 10px;
}

.program_structure {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
}
.mandatory_block {
    height: 100%;
}
.mandatory_block_inner {
    height: 100%;
    padding: 20px;
    background-color: #163269;
    color: white;
    border-radius: 35px;

}
.modules_block {

}
.modules {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    align-items: stretch;
}
.module {
    background-color: #ffdd57;
    color: #000;
    padding: 25px 16px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 1100px) {
    .program_structure {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 600px) {
    .modules {
        grid-template-columns: 1fr;
    }
}