.steveBloxBtn {
    padding: 5px 20px;
    border: 2px solid #005ae9 !important;
    border-radius: 10px;
    transition: all 0.3s;
    cursor: pointer;
    background: white !important;
    font-size: 1rem;
    height: auto;
}

.steveBloxBtn:not([disabled]):hover {
    background: #005ae9 !important;
    color: white !important;
    /*font-size: 1.5em;*/
}

.steveBloxBtnSelected {
    background: #005ae9 !important;
    color: white !important;
}

.bhargaBtn {
    height: auto;
    outline: none;
    cursor: pointer;
    border: none;
    padding: 5px 20px;
    margin: 0;
    font-family: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 17px;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(to right, #005ae9, #114f8d) !important;
    color: ghostwhite !important;
}

.bhargaBtn span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

.bhargaBtn:hover span {
    color: #ffffff !important;
}

.bhargaBtn::before,
.bhargaBtn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.bhargaBtn::before {
    content: "";
    background: #49AB6C !important;
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.bhargaBtn:hover::before {
    transform: translate3d(100%, 0, 0);
}

.myButton {
    background-color: #153C6B !important;
    color: white !important;
    border: none;
    padding: 5px 10px;
    height: auto;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myButton:hover {
    background-color: #1b457a !important;
    color: white !important;
}

.greyButton {
    background-color: #6F8695 !important;
    color: white !important;
    border: none;
    padding: 7px 10px;
    height: auto;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.greyButton:hover {
    background-color: #6F8695 !important;
    color: white !important;
}

.greenButton {
    background-color: #87d068 !important;
    color: white !important;
    border: none;
    padding: 7px 10px;
    height: auto;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.greenButton:hover {
    background-color: #87d068 !important;
    color: white !important;
}

.blackerBlueButton {
    background-color: #163269 !important;
    color: white !important;
    border: none;
    padding: 7px 10px;
    height: auto;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blackerBlueButton:hover {
    background-color: #163269 !important;
    color: white !important;
}

.redBtn {
    background-color: #FE4646 !important;
    color: white !important;
    border: none;
    padding: 7px 10px;
    height: auto;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.redBtn:hover {
    background-color: #FE4646 !important;
    color: white !important;
}

.defaultButton {
    background-color: white !important;
    color: black !important;
    border: none;
    padding: 5px 10px;
    height: auto;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whiteBlueButton {
    background-color: #00FFEB !important;
    border: none;
    padding: 5px 10px;
    height: auto;
    border-radius: 0px;
    cursor: pointer;
    min-width: 125px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whiteBlueButton * {
    color: #01003F !important;
    font-size: 1rem;
    font-weight: 700 !important;
}

.hoverBtn {
    height: auto;
    padding: 0;
    font-size: 18px;
    color: black !important;
    font-family: inherit;
    font-weight: 800;
    cursor: pointer;
    position: relative;
    border: none;
    background: none;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
}

.hoverBtn:focus,
.hoverBtn:hover {
    color: black !important;
}

.hoverBtn:focus:after,
.hoverBtn:hover:after {
    width: 100%;
    left: 0%;
}

.hoverBtn:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: black !important;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}


.northButton {
    display: inline-block;
    border-radius: 4px;
    background: linear-gradient(to right, #005ae9, #114f8d) !important;
    border: none;
    color: #FFFFFF !important;
    text-align: center;
    font-size: 17px;
    padding: 5px 20px;
    width: 130px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    height: auto;
}

.northButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.northButton span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.northButton:hover span {
    padding-right: 15px;
    color: #FFFFFF !important;
}

.northButton:hover span:after {
    opacity: 1;
    right: 0;
    color: #FFFFFF !important;
}
