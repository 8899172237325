.bannerFormBlock {
    padding: 20px;
    width: 450px;
    border-radius: 25px;
    background-color: #F4F5FA;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap: 15px;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
}

.bannerFormText {
    font-size: 1.5rem;
    font-weight: 700;
}

.bannerFormInputBlock {
    width: 100%;
    margin: 0;
    padding: 0;
}

.bannerFormInput {
    font-size: 1rem;
    padding: 10px 15px;
    font-weight: 400 !important;
}

.bannerText {
    text-align: left;
    max-width: 700px;
    font-size: 2.5rem;
    font-weight: 700;
}

.backImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 95%;
    top: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.backImage img {

}

.underBannerBlock {
    border-radius: 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
}

@media screen and (max-width: 1300px) {
    .fadeBlock {
        width: 100%;
    }
    .bannerText {
        width: 100%;
        text-align: center;
        font-size: 2rem;
    }
    .bannerFormBlock {
        width: 100% !important;
    }
    .bannerFormText {
        font-size: 1.2rem;
    }
    .underBannerBlock {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 425px) {
    .bannerText {
        font-size: 1.6rem;
    }
}