.main {
    width: 100%;
    position: relative;
    border-radius: 25px;
}

.headerText {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
}

.eachSlide {
    /*border: 1px solid white;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.eachSlide img {
    max-height: 50px;
    max-width: 150px;

}