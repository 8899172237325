.header {
    background: #ffffff;
    padding: 0 20px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    border-bottom: 1px solid #D7D7D7;
    transition: 0.3s;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*max-width: 1440px;*/
    width: 100%;
}

.navIconBlock {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 5px;
    /*border: 2px solid;*/
    cursor: pointer;
    transition: 0.3s;
    background-color: #e7e7e7;
    /*border-radius: 5px;*/
}
.navIconBlock:hover {
    background-color: #dedede;
}
.navIconBlock img {
    width: 100%;
    height: 100%;
}

.profileBlock {
    padding: 10px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    transition: 0.3s;
    position: relative;
}
.profileBlock img {
    width: 100%;
    height: 100%;
}
.profileBlock:hover {
    background-color: #F5F5F5;
}