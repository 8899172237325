.content {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.card {
    background: #E9E9E9;
    padding: 15px 30px;
    border-radius: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 270px;
}

.card img {
    margin-bottom: 20px;
    height: 60px;
}

.gray_box {
    padding: 40px 0;
    width: 100%;
    max-width: 1440px;
    overflow: hidden;
}

.personImg, .imgPeople {
    width: 300px;
}

.imgBox {
    width: 40%;
    display: flex;
    justify-content: center;
}

.personText {
    text-align: center;
    background: #110BFF;
    color: white;
    padding: 10px 30px;
    margin-bottom: 40px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
}

.cardProgs {
    background: #E9E9E9;
    width: 100%;
    height: 250px;
    border-radius: 35px;
    color: #110BFF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 30px 0;
}

.cardProgs h2 {
    width: 45%;
    font-weight: 800;
}

@media screen and (max-width: 768px) {
    /*.card {*/
    /*    width: 100%;*/
    /*}*/

}