.copyLinkBtn {
    cursor: pointer;
    color: #185ee0;
}

.copyLinkBtn:hover {
    color: #2F2585;
}

.createFormBtn {
    cursor: pointer;
}

.rejectBtn {
    color: #FF6B6B;
    cursor: pointer;
}

.rejectBtn:hover {
    color: #cb3535;
}

.divider *{
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 15px;
    flex-direction: column;
}

.formInputBlock {
    width: 100%;
    margin: 0;
    padding: 0;
}

.formInput {
    font-size: 0.9rem;
    padding: 10px 15px;
    font-weight: 400 !important;
}

.fileItemDeleteBadge {
    cursor: pointer;
    background-color: #f5222d;
    transition: 0.3s;
}

.fileItemDeleteBadge:hover {
    background-color: #ff6973;
    transition: 0.3s;
}

.fileItem {
    color: black;
    transition: 0.3s;
    cursor: pointer;
}

.fileItem:hover {
    color: #00bbff;
    transition: 0.3s;
}

.eyeFileItem {
    background-color: #163269;
    color: white;
    transition: 0.3s;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}
.eyeFileItem:hover {
    background-color: #4b62c9;
}