.form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 15px;
    flex-direction: column;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
}

.completeBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 15px;
    flex-direction: column;
    background-color: #292929;
    border-radius: 10px;
    padding: 20px;
}

.formInputBlock {
    width: 100%;
    margin: 0;
    padding: 0;
}

.formInput {
    font-size: 1rem;
    padding: 10px 15px;
    font-weight: 400 !important;
}
.formSelect {
    font-size: 1rem;
    font-weight: 400 !important;
}

.fileItemDeleteBadge {
    cursor: pointer;
    background-color: #f5222d;
    transition: 0.3s;
}

.fileItemDeleteBadge:hover {
    background-color: #ff6973;
    transition: 0.3s;
}

.fileItem {
    color: black;
    transition: 0.3s;
    cursor: pointer;
}

.fileItem:hover {
    color: #00bbff;
    transition: 0.3s;
}

.eyeFileItem {
    background-color: #163269;
    color: white;
    transition: 0.3s;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}
.eyeFileItem:hover {
    background-color: #4b62c9;
}