.header {
    background: #ffffff;
    padding: 0 20px;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-bottom: 1px solid #D7D7D7;
    z-index: 100;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
}

.hoverText {
    text-align: center;
    color: black;
    position: relative;
}
.hoverText:hover {
    color: black;
}

.hoverText::after {
    content: '';
    position: absolute;
    width: 0;
    background-color: black;
    height: 2px;
    bottom: -8px;
    transition: 0.3s;
    left: 0;
}

.hoverText:hover::after {
    width: 100%;
    transition: 0.3s;
}