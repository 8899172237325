.backImage {
    padding: 0 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 70vh;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}