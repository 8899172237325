.divider *{
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.eachCollapse {
    width: 100%;
}

.deleteBtn {
    padding: 5px 10px;
    color: #FF6B6B;
    cursor: pointer;
    border: 1px solid #FF6B6B;
}

.deleteBtn:hover {
    color: #cb3535;
    border: 1px solid #cb3535;
}

.deleteBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
}