.studentListItem {
    transition: 0.3s;
    padding: 5px 10px;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
}
.studentListItem:hover {
    transition: 0.3s;
    background-color: #f3f3f3;
}