.formBlock {
    padding: 20px;
    width: calc(100% - 40px);
    border-radius: 25px;
    background-color: #F4F5FA;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap: 15px;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 700px;
}

.formInputBlock {
    width: 100%;
    margin: 0;
    padding: 0;
}

.formInput {
    font-size: 1rem;
    padding: 10px 15px;
    font-weight: 400 !important;
}