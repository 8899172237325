.mainBlock {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    background-color: #292929;
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.hoverText {
    text-align: center;
    color: white;
    position: relative;
}
.hoverText:hover {
    color: white;
}