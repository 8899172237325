.cardHeader {
    padding: 0 !important;
}
.myCardBlock {
    /*background-color: yellow !important;*/
    /*border: 1px solid red !important;*/
    cursor: pointer;
    overflow: hidden;
}
.myCardBlock > div:first-child {
    padding: 0 !important;
    /*background-color: green !important;*/
    min-height: auto;
    height: 50px;
}

.myCardBlock > div:first-child > * {
    height: 100%;
}
.myCardBlock > div:first-child > * > * {
    height: 100%;
}