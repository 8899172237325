.changeBtn {
    padding: 5px 10px;
    color: #163269;
    cursor: pointer;
    border: 1px solid #163269;
    transition: 0.3s;
}

.changeBtn:hover {
    color: #7d95c0;
    border: 1px solid #7d95c0;
}

.changeBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
}